<template>
  <div class="flex flex-col gap-2">
    <h1>Nieuwe {{ typeLabel.toLowerCase() }} aanmaken</h1>
    <small v-if="journal">Financieel dagboek: <b>{{ journal.label }}</b></small>

    <slot></slot>
    <div class="mt-2 w-full">
      <FormCreditNote
        v-if="type === 'CREDIT_NOTE'"
        v-model="form"
        nieuw
        :disabled="api.loading.value"
        @submit="onSubmit"
      />
      <FormFactuur
        v-else-if="type === 'INVOICE'"
        v-model="form"
        nieuw
        :disabled="api.loading.value"
        @submit="onSubmit"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import clone from 'just-clone'

import FormCreditNote from '@/components/Form/Facturatie/CreditNote.vue'
import FormFactuur from '@/components/Form/Facturatie/Factuur.vue'

import { FINANCIAL_JOURNALS } from '@/constants/OPTIONS'
import useApi from '@/hooks/useApi'
// import useRouteQueryDate from '@/hooks/useRouteQueryDate'

const props = defineProps({
  type: {
    type: String,
    default: 'INVOICE',
  },
  typeLabel: {
    type: String,
    default: 'Factuur',
  },
  initialValue: {
    type: Object,
  },
})

const api = useApi()
const router = useRouter()
const route = useRoute()
const journal_id = computed(() => route.query.journal_id)

const journal = computed(() => FINANCIAL_JOURNALS.find(j => j.value == journal_id.value))

const defaultForm = {}
const form = ref(clone(props.initialValue || defaultForm))

onMounted(() => {
  if (props.type === 'INVOICE') {
    api('FINANCIAL_DOCS_NEXT', { journal_id: journal_id.value, type: props.type }).then(({ data: doc_number }) => {
      if (doc_number > 0) {
        form.value.doc_number = doc_number
      }
    })
  }
})

const onSubmit = _data => {
  const data = clone(_data)
  data.type = props.type
  data.journal_id = journal_id.value
  api.post('FINANCIAL_DOCS_CREATE', data).then(() => {
    router.push({ path: route.path.split('/nieuw')[0], query: { journal_id: data.journal_id } })
  })
}
</script>
